/* Optional: Makes the sample page fill the window. */
#root,
#map,
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
