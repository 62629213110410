#controls {
  position: absolute;
  /* set height to 0. other wise it would cover google map's control */
  height: 0;
  bottom: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.left-panel {
  position: absolute;
  left: 10px;
  top: -30px;
}

.control-button {
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
}
.control-button img {
  width: 100%;
}
.gray-background {
  background-color: #f9f9f9;
}

.user-photo {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-height: 100px;
  box-sizing: border-box;
}
