.container {
  margin: 50px;
  text-align: center;
  font-family: sans-serif;
  font-weight: 700;
  border: 1px solid #999;
  border-radius: 10px;
  font-size: 2em;
  color: red;
}
