#floating-panel {
  padding: 5px 180px;
  background-color: #eaeaea;
  text-align: center;
  font-family: sans-serif;
  font-weight: 700;
  border: 1px solid #999;
}
#note {
  font-size: 2em;
}
